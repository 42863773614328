<template>
  <v-data-table
    ref="tableRef"
    v-model="localSelectedItems"
    dense
    disable-pagination
    fixed-header
    :headers="tableHeaders"
    hide-default-footer
    :item-class="getClass"
    item-key="id"
    :items="loan.loanReturns"
    must-sort
    :show-select="true"
    sort-by="createdAt"
    width="100%"
  >
    <template #[`item.createdAt`]="{ item }">
      <span class="highlight">{{ formatDate(item.createdAt, 'MMM d h:mm a') }} </span>
    </template>

    <template #[`item.returnQuantity`]="{ item }">
      <pretty-number :value="item.returnQuantity" />
    </template>

    <template #[`item.actions`]="{ item }">
      <div class="actions d-flex justify-center">
        <confirm-dialog-wrapper
          v-if="loan.side === 'BORROWER'"
          :options="{
            message: 'You are about to cancel this return proposal.',
            title: 'Return proposal cancellation',
            rejectText: 'Keep return proposal',
            acceptText: 'Cancel return proposal',
          }"
          v-on="$listeners"
        >
          <template #default="{ confirm }">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <aurora-btn
                  class="icon-action"
                  data-test="cancel-return"
                  icon
                  :loading="updatingItems[item.id] && actionInProgress === 'cancel'"
                  timeframe="settleLoans"
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  @click="confirm(() => cancelReturnProposal(item.id))"
                >
                  <v-icon size="24">mdi-close-circle</v-icon>
                </aurora-btn>
              </template>
            </v-tooltip>
          </template>
        </confirm-dialog-wrapper>

        <confirm-dialog-wrapper
          v-if="loan.side === 'LENDER'"
          :options="{
            message: 'You are about to reject this return proposal.',
            title: 'Return proposal rejection',
            rejectText: 'Keep return proposal',
            acceptText: 'Reject return proposal',
          }"
          v-on="$listeners"
        >
          <template #default="{ confirm }">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <aurora-btn
                  class="icon-action"
                  data-test="reject-return"
                  icon
                  :loading="updatingItems[item.id] && actionInProgress === 'reject'"
                  timeframe="settleLoans"
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  @click="confirm(() => rejectReturnProposal(item.id))"
                >
                  <v-icon size="24">mdi-close-circle</v-icon>
                </aurora-btn>
              </template>
            </v-tooltip>
          </template>
        </confirm-dialog-wrapper>

        <confirm-dialog-wrapper
          v-if="loan.side === 'LENDER'"
          :options="{
            message: 'You are about to accept this return proposal.',
            title: 'Return proposal acceptance',
            rejectText: 'Keep return proposal',
            acceptText: 'Accept return proposal',
            color: 'success',
          }"
          v-on="$listeners"
        >
          <template #default="{ confirm }">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <aurora-btn
                  class="icon-action"
                  data-test="accept-return"
                  icon
                  :loading="updatingItems[item.id] && actionInProgress === 'accept'"
                  timeframe="settleLoans"
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  @click="confirm(() => acceptReturnProposal(item.id))"
                >
                  <v-icon size="24">mdi-check</v-icon>
                </aurora-btn>
              </template>
            </v-tooltip>
          </template>
        </confirm-dialog-wrapper>
      </div>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import Component from 'vue-class-component';
import { formatDate } from '@/utils/helpers/dates';
import { LoanDetailsLoan, OpenRecall, ReturnProposalActionResponse } from '@/utils/api/loans';
import { ApiError } from '@/utils/errors';
import { i18nServerMessage } from '@/utils/helpers/rest-response';

const headers: DataTableHeader[] = [
  { text: 'When', value: 'createdAt' },
  { text: 'Return Quantity', value: 'returnQuantity', align: 'end' },
  { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
];

@Component({
  props: {
    loan: {
      type: Object as PropType<LoanDetailsLoan>,
      required: true,
    },
    selectedItems: {
      type: Array as PropType<OpenRecall[]>,
      required: true,
    },
  },
})
export default class LoanReturns extends Vue {
  protected readonly loan!: LoanDetailsLoan;
  protected readonly selectedItems!: OpenRecall[];

  protected actionInProgress: 'cancel' | 'reject' | 'accept' | null = null;
  protected updatingItems: Record<string, boolean> = {};
  protected failedReturnProposalIds: string[] = [];
  protected tableHeaders = headers;
  protected formatDate = formatDate;

  protected set localSelectedItems(value: OpenRecall[]) {
    this.$emit('update:selected-items', value);
  }

  protected get localSelectedItems(): OpenRecall[] {
    return this.selectedItems;
  }

  protected async cancelReturnProposal(returnProposalId: string): Promise<void> {
    this.actionInProgress = 'cancel';
    this.$set(this.updatingItems, returnProposalId, true);
    const res: ReturnProposalActionResponse = { id: returnProposalId };

    try {
      await this.$api.openLoans.cancelReturnProposal(this.loan.id, returnProposalId);
    } catch (e) {
      res.error = new ApiError(i18nServerMessage(e as Error)).message;
    }
    this.$set(this.updatingItems, returnProposalId, false);
    this.actionInProgress = null;

    if (res.error) {
      this.$snackbar.error(res.error);
      this.failedReturnProposalIds.push(returnProposalId);
    } else {
      this.$snackbar.show({
        color: 'primary',
        message: `Your return proposal has been cancelled.`,
        timeout: 3000,
      });
    }
  }

  protected async rejectReturnProposal(returnProposalId: string): Promise<void> {
    this.actionInProgress = 'reject';
    this.$set(this.updatingItems, returnProposalId, true);
    const res: ReturnProposalActionResponse = { id: returnProposalId };

    try {
      await this.$api.openLoans.rejectReturnProposal(this.loan.id, returnProposalId);
    } catch (e) {
      res.error = new ApiError(i18nServerMessage(e as Error)).message;
    }
    this.$set(this.updatingItems, returnProposalId, false);
    this.actionInProgress = null;

    if (res.error) {
      this.$snackbar.error(res.error);
      this.failedReturnProposalIds.push(returnProposalId);
    } else {
      this.$snackbar.show({
        color: 'primary',
        message: `Your return proposal has been rejected.`,
        timeout: 3000,
      });
    }
  }

  protected async acceptReturnProposal(returnProposalId: string): Promise<void> {
    this.actionInProgress = 'accept';
    this.$set(this.updatingItems, returnProposalId, true);
    const res: ReturnProposalActionResponse = { id: returnProposalId };

    try {
      await this.$api.openLoans.acceptReturnProposal(this.loan.id, returnProposalId);
    } catch (e) {
      res.error = new ApiError(i18nServerMessage(e as Error)).message;
    }
    this.$set(this.updatingItems, returnProposalId, false);
    this.actionInProgress = null;

    if (res.error) {
      this.$snackbar.error(res.error);
      this.failedReturnProposalIds.push(returnProposalId);
    } else {
      this.$snackbar.show({
        color: 'primary',
        message: `Your return proposal has been accepted.`,
        timeout: 3000,
      });
    }
  }

  protected getClass(rowItem: OpenRecall): string | void {
    if (this.failedReturnProposalIds.includes(rowItem.id)) {
      return 'error--text';
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-action {
  cursor: pointer;
}
.actions {
  gap: 0.5rem;
}

.table-container tr,
.summary {
  /* Tone down grey to increase contrast between bold and non-bold text */
  color: #888;
}

// specificy hack to apply error-text to override the highlight color
tr.error--text .highlight {
  color: #ff5252 !important;
}

.highlight,
.summary strong {
  color: white;
  font-weight: bold;
}

.theme--light .table-container tr,
.theme--light .summary {
  color: #777;
}

.theme--light .highlight,
.theme--light .summary strong {
  color: black;
}
</style>
