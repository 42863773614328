<template>
  <v-col cols="4">
    <v-card class="flex d-flex flex-column" variant="outlined">
      <v-card-title>Independent Amount</v-card-title>
      <v-card-subtitle
        >This view approximates values which may be owed to the NSCC as part of the SFT clearing
        service based on the SFT transactions managed on Aurora. The estimates will not include the
        impact of transactions done away.
      </v-card-subtitle>
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th></th>
              <th class="text-right">Yesterday</th>
              <th class="text-right">Today</th>
            </tr>
          </thead>
          <tbody v-if="data">
            <tr>
              <td>IA Borrows</td>
              <td class="text-right">{{ data.yesterdayIABorrow }}</td>
              <td class="text-right">{{ data.todayIABorrow }}</td>
            </tr>
            <tr>
              <td>IA Loans</td>
              <td class="text-right">{{ data.yesterdayIALend }}</td>
              <td class="text-right">{{ data.todayIALend }}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td class="text-right">{{ data.totalIAYesterday }}</td>
              <td class="text-right">{{ data.totalIAToday }}</td>
            </tr>
            <tr>
              <td>Delta</td>
              <td class="text-right" colspan="2">
                <b>{{ data.iaDelta }}</b>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Api } from '@/modules/analytics/types/statistics';
import axios from 'axios';
import { formatDecimalAsCurrencyString } from '@/utils/helpers/auction-numbers';

@Component({})
export default class IndependentAmountsStatistics extends Vue {
  protected data!: null | Api.IaDashboardData = null;

  protected async mounted(): Promise<void> {
    await this.fetchData();
  }

  protected async fetchData(): Promise<void> {
    try {
      const res = await axios.get<Api.IaDashboardResponse>(`/api/1/analytics/dashboard/ia`);
      this.normalizeData(res.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
    }
  }

  protected normalizeData(data: Api.IaDashboardResponse): void {
    this.data = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        formatDecimalAsCurrencyString('USD', value, 2),
      ])
    );
  }
}
</script>
