<template>
  <!-- template must have 1 direct child, we wrap the contents in a <div>
       with "display: contents", making sure layout rendering is not affected -->
  <div style="display: contents">
    <confirm-dialog-wrapper
      v-if="selectedItems.length && side === 'BORROWER'"
      :options="{
        message: 'You are about to cancel all selected late returns.',
        title: 'Late return cancellation',
        rejectText: 'Keep late returns',
        acceptText: 'Cancel late returns',
      }"
    >
      <template #default="{ confirm }">
        <aurora-btn
          data-test="cancel-return"
          :disabled="!hasOpsUserRole || actionInProgress !== null"
          :loading="actionInProgress === 'cancel'"
          timeframe="settleLoans"
          @click="confirm(batchCancel)"
        >
          Cancel proposed returns ({{ selectedItems.length }})
        </aurora-btn>
      </template>
    </confirm-dialog-wrapper>
    <confirm-dialog-wrapper
      v-if="selectedItems.length && side === 'LENDER'"
      :options="{
        message: 'You are about to reject all selected late returns.',
        title: 'Late return rejection',
        rejectText: 'Close',
        acceptText: 'Reject late returns',
      }"
    >
      <template #default="{ confirm }">
        <aurora-btn
          color="error"
          data-test="reject-return"
          :disabled="!hasOpsUserRole || actionInProgress !== null"
          :loading="actionInProgress === 'reject'"
          timeframe="settleLoans"
          @click="confirm(batchReject)"
        >
          Reject proposed returns ({{ selectedItems.length }})
        </aurora-btn>
      </template>
    </confirm-dialog-wrapper>
    <confirm-dialog-wrapper
      v-if="selectedItems.length && side === 'LENDER'"
      :options="{
        message: 'You are about to accept all selected late returns.',
        title: 'Late return acceptance',
        rejectText: 'Close',
        acceptText: 'Accept late returns',
        color: 'info',
      }"
    >
      <template #default="{ confirm }">
        <aurora-btn
          color="info"
          data-test="accept-return"
          :disabled="!hasOpsUserRole || actionInProgress !== null"
          :loading="actionInProgress === 'accept'"
          timeframe="settleLoans"
          @click="confirm(batchAccept)"
        >
          Accept proposed returns ({{ selectedItems.length }})
        </aurora-btn>
      </template>
    </confirm-dialog-wrapper>
  </div>
</template>

<script lang="ts">
import { Return } from '@/utils/api/loans';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

@Component({
  props: {
    side: {
      type: String as PropType<'BORROWER' | 'LENDER'>,
      required: true,
    },
    selectedItems: {
      type: Array as PropType<Return[]>,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['hasOpsUserRole', 'hasTraderUserRole']),
  },
})
export default class LoanReturnsBatchActions extends Vue {
  // props
  protected readonly side!: 'BORROWER' | 'LENDER';
  protected readonly selectedItems!: Return[];

  // store state
  protected readonly hasOpsUserRole!: boolean;
  protected readonly hasTraderUserRole!: boolean;

  protected actionInProgress: null | 'cancel' | 'accept' | 'reject' = null;

  public async batchCancel(): Promise<void> {
    this.actionInProgress = 'cancel';
    const res = await this.$api.borrowerLoans.batchCancelLoanReturns(
      this.selectedItems.map(({ id }) => id)
    );
    this.actionInProgress = null;
    this.$emit('update:selected-items', []);

    if (res.failedItems.length) {
      this.$snackbar.error(
        `${res.failedItems.length} return proposal${
          res.failedItems.length > 1 ? `s` : ``
        } could not be cancelled.`
      );
    } else {
      this.$snackbar.show({
        color: 'primary',
        message: `Your return prososals have been cancelled.`,
        timeout: 3000,
      });
    }
  }

  public async batchReject(): Promise<void> {
    this.actionInProgress = 'reject';
    const res = await this.$api.lenderLoans.batchRejectLoanReturns(
      this.selectedItems.map(({ id }) => id)
    );
    this.actionInProgress = null;
    this.$emit('update:selected-items', []);

    if (res.failedItems.length) {
      this.$snackbar.error(
        `${res.failedItems.length} return proposal${
          res.failedItems.length > 1 ? `s` : ``
        } could not be rejected.`
      );
    } else {
      this.$snackbar.show({
        color: 'primary',
        message: `Your return prososals have been rejected.`,
        timeout: 3000,
      });
    }
  }

  public async batchAccept(): Promise<void> {
    this.actionInProgress = 'accept';
    const res = await this.$api.lenderLoans.batchAcceptLoanReturns(
      this.selectedItems.map(({ id }) => id)
    );
    this.actionInProgress = null;
    this.$emit('update:selected-items', []);

    if (res.failedItems.length) {
      this.$snackbar.error(
        `${res.failedItems.length} return proposal${
          res.failedItems.length > 1 ? `s` : ``
        } could not be accepted.`
      );
    } else {
      this.$snackbar.show({
        color: 'primary',
        message: `Your return prososals have been accepted.`,
        timeout: 3000,
      });
    }
  }
}
</script>
<style lang="scss" scoped></style>
